type MonthFormat = 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';

/**
 * Takes a local date, converts it to UTC, returns ISO String ('yyyy-MM-ddThh:mm:ss.sssZ')
 * @param d Date, string, null, or undefined
 * @returns ISO String ('yyyy-MM-ddThh:mm:ss.sssZ')
 */
export const formatDateForXhr = (d: Date | string | null | undefined): string | null => {
    if (!d) {
        return null;
    }
    const utc = convertLocalDateToUTC(d);
    return utc.toISOString();
};

/**
 * Takes local date, resets the time to noon, converts to UTC, returns ISO String ('yyyy-MM-ddThh:mm:ss.sssZ')
 * @param d Date, string, null, or undefined
 * @returns ISO String ('yyyy-MM-ddThh:mm:ss.sssZ')
 */
export const formateDateOnlyForXhr = (d: Date | string | null | undefined): string | null => {
    if (!d) {
        return null;
    }
    const localDate = new Date(d);
    localDate.setHours(12);
    localDate.setMinutes(0);
    localDate.setSeconds(0);

    const utcDate = convertLocalDateToUTC(localDate);
    return utcDate.toISOString();
};

/**
 * Returns date in format mm/dd/yyyy
 * @param d date string
 * @param monthFormat month format string: "numeric" | "2-digit" | "long" | "short" | "narrow"
 * @returns mm/dd/yyyy
 */
export const formatDate = (d: string, monthFormat?: MonthFormat) => {
    return new Date(d).toLocaleDateString([], { day: 'numeric', month: monthFormat ?? 'numeric', year: 'numeric' });
};

/**
 * Takes a UTC date, converts to local time and returns string format MM/dd/yyyy
 * @param d utc date or date string
 * @param monthFormat month format string
 * @returns MM/dd/yyyy
 */
export const formatDateUtc = (d: string | Date | null | undefined, monthFormat?: MonthFormat) => {
    if (!d) {
        return null;
    }
    const date = convertUTCToLocalDate(d);
    return date.toLocaleDateString([], { day: 'numeric', month: monthFormat ?? 'numeric', year: 'numeric' });
};

/**
 * Returns date in format M/d/yyyy, h:mm:ss AM/PM
 * @param d date string
 * @returns {string} M/d/yyyy, h:mm:ss AM/PM
 */
export const formatDateTime = (d: string) => {
    return new Date(d).toLocaleDateString([], {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
};

export const getTimeOffset = () => {
    const timezone_offset_min = new Date().getTimezoneOffset(),
        offset_hrs = Math.abs(timezone_offset_min / 60),
        offset_min = Math.abs(timezone_offset_min % 60);
    let offset_hrs_str = '',
        offset_min_str = '',
        timezone_standard = '';

    if (offset_hrs < 10) offset_hrs_str = '0' + offset_hrs;

    if (offset_min < 10) offset_min_str = '0' + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0) timezone_standard = '+' + offset_hrs_str + ':' + offset_min_str;
    else if (timezone_offset_min > 0) timezone_standard = '-' + offset_hrs_str + ':' + offset_min_str;
    else if (timezone_offset_min == 0) timezone_standard = 'Z';

    return timezone_standard;
};

/**
 * @param d any string convertabel to a date
 * @returns {string} format dd MM yyyy
 */
export const formatDateToDDMMYYYY = (d: string) => {
    const date = new Date(d);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return `${day} ${month} ${year}`;
};

/**
 * @param d any string convertable to a date
 * @returns {string} format MMM dd, yyyy
 */
export const formatDateToMMMDDYYYY = (d: string) => {
    const date = new Date(d);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    return `${month} ${day}, ${year}`;
};

/**
 * Converts a UTC date to local time zone
 * @param d a UTC string convertable to a date
 * @returns {Date} local time zone date
 */
export const convertUTCToLocalDate = (d: string | Date) => {
    const date = new Date(d);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

/**
 * Converts a local datetime to UTC
 * @param d takes a Date in Local time zone
 * @returns {Date} UTC date
 */
export const convertLocalDateToUTC = (d: string | Date) => {
    const dateStr = new Date(d).toUTCString();
    return new Date(dateStr);
};

/**
 * Formats date as date picker input value
 * @param {string | null} date Date string to format
 * @returns {string}           Formatted date string
 */
export const formatDateToInputValue = (date: string | null): string =>
    date ? new Date(date).toISOString().substring(0, 10) : '';

export const formatDateOnlyToInputValue = (date: string | null): string => {
    if (!date) {
        return '';
    }
    const localDate = new Date(date);
    localDate.setHours(12, 0, 0);
    return new Date(localDate).toISOString().substring(0, 10);
};

/**
 * Removes time component from datetime
 * @param {string | Date} dateTime Date or date string remove time from
 * @returns {Date}        Date object without time component
 */
export const getDateWithoutTime = (dateTime: string | Date): Date => {
    const res = new Date(dateTime);
    res.setUTCHours(0, 0, 0, 0);
    return res;
};
